import React  from 'react'
import Header from '../components/header'
import "../style/product.less"
import { navigate,Link } from 'gatsby'
import product1 from "../images/product/listImg1.png"
import product2 from "../images/product/listImg2.png"
import product3 from "../images/product/listImg3.png"
import product4 from "../images/product/listImg4.png"
export default function Product() {
    
    const productList = [
        {id:0,img:product1,title:"Securely collaborate"},
        {id:1,img:product2,title:"Create value"},
        {id:2,img:product3,title:"Comply"},
        {id:3,img:product4,title:"Govern"}
    ]
    return (
        <div className='product'>
            <Header />
            <div className='product_list'>
                <ul>
                    {
                        productList.map((item,index)=>{
                            return (
                                <li key={item.id} >
                                    <div><img src={item.img} alt="" /></div>
                                    <h3>{item.title}</h3>
                                    <a className='link' target="_blank" href={"/productList?id="+index}></a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>

    )
}
